import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Theme from 'styles/_theme';
import GlobalStyle from 'styles/_global';

import { AnimationFade } from 'libs/animation';

import Seo from 'components/Seo/Seo';
import Layout from 'components/Layout/Layout';

import { Wrapper } from 'styles/_mixins';

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 10rem);

  & ${Wrapper} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    & > a {
      width: fit-content;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: fit-content;
`;

const Title = styled.h1`
  margin-bottom: 0.5rem;
  font-size: clamp(7rem, 12vw, 10rem);
  color: ${({ theme: { colors } }) => colors.white};
`;

const Description = styled.p`
  margin-bottom: 5rem;
  font-size: 1.8rem;
`;

const Button = styled(Link)`
  text-transform: uppercase;
  padding: 0.85rem 1rem;
  font-family: inherit;
  color: ${({ theme: { colors } }) => colors.white};
  background: ${({ theme: { colors } }) => colors.mint};
  border: 0.1rem solid ${({ theme: { colors } }) => colors.mint};
  transition: all 0.3s ease-in;

  &:hover {
    background: ${({ theme: { colors } }) => colors.darkmint};
    border: 0.1rem solid ${({ theme: { colors } }) => colors.darkmint};
  }
`;

const NotFoundPage = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/error/" }) {
        frontmatter {
          id
          slug
          title
          descrip
          linkTitle
          linkText
          seoTitle
          seoDescription
        }
      }
    }
  `);

  const { title, descrip, linkTitle, linkText, seoTitle, seoDescription } =
    datas.markdownRemark.frontmatter;

  return (
    <Theme>
      <GlobalStyle />
      <Layout backgrounds>
        <Seo title={seoTitle} description={seoDescription} />
        <Container>
          <AnimationFade rootMargin='0px 0px' custom={0}>
            <Wrapper>
              <Content>
                <Title>{title}</Title>
                <Description>{descrip}</Description>
                <Button to='/' title={linkTitle}>
                  {linkText}
                </Button>
              </Content>
            </Wrapper>
          </AnimationFade>
        </Container>
      </Layout>
    </Theme>
  );
};

export default NotFoundPage;
